import React, { useState } from "react"
import { useQueryParam, DelimitedArrayParam } from "use-query-params"
import { identify, trackEvent } from "../utils/trackEvent"

const RoiContext = React.createContext({
  data: null,
  supportRequests: 0,
  stepDone: (step, payload) => {},
})

const getItemLocalStorage = key =>
  typeof window !== `undefined` ? JSON.parse(localStorage.getItem(key)) : null
const setLocalStorage = (key, data) =>
  localStorage.setItem(key, JSON.stringify(data))
const resetLocalStorage = () => localStorage.removeItem(LOCAL_STORAGE_KEY)

const MULTIPLIER = 1
const CORRECTLY_RESOLVED = 1.0
const LOCAL_STORAGE_KEY = "roi-data"

const defaultState = {
  supportRequests: 240_000,
  serviceAgents: 1_200,
  currency: "eur",
  averageSalary: 4500,
  productCategory: null,
  workingDaysPerMonth: 20,
  workingHoursPerDay: 8,
  companyName: null,
  email: null,
  costPerCase: 0,
  costPerCaseSaved: 0,
  savedHours: 0,
  costSavedYearly: 0,
  timePerCase: 0,
  selfServiceCases: 100,
  casesSolvedBySelfService: 40,
  costSavedBySelfService: 100,
  costSavedByAgent: 70,
}

export const RoiProvider = ({ children }) => {
  const data = getItemLocalStorage(LOCAL_STORAGE_KEY)

  const [supportRequests, setSupportRequests] = useState(
    data?.roiInputs?.supportRequests || defaultState.supportRequests
  )
  const [serviceAgents, setServiceAgents] = useState(
    data?.roiInputs?.serviceAgents || defaultState.serviceAgents
  )
  const [currency, setCurrency] = useState(
    data?.roiInputs?.currency || defaultState.currency
  )
  const [averageSalary, setAverageSalary] = useState(
    data?.roiInputs?.averageSalary || defaultState.averageSalary
  )
  const [productCategory, setProductCategory] = useState(
    data?.roiInputs?.productCategory || defaultState.productCategory
  )
  const [workingDaysPerMonth, setWorkingDaysPerMonth] = useState(
    defaultState.workingDaysPerMonth
  )
  const [workingHoursPerDay, setWorkingHoursPerDay] = useState(
    defaultState.workingHoursPerDay
  )

  const [companyName, setCompanyName] = useState(
    data?.roiDetails?.companyName || defaultState.companyName
  )
  const [email, setEmail] = useState(
    data?.roiDetails?.email || defaultState.email
  )

  const [costPerCase, setCostPerCase] = useState(defaultState.costPerCase)
  const [costPerCaseSaved, setCostPerCaseSaved] = useState(
    defaultState.costPerCaseSaved
  )
  const [savedHours, setSavedHours] = useState(defaultState.savedHours)
  const [costSavedYearly, setCostSavedYearly] = useState(
    defaultState.costSavedYearly
  )
  const [timePerCase, setTimePerCase] = useState(defaultState.timePerCase)

  const [selfServiceCases, setSelfServiceCases] = useState(
    defaultState.selfServiceCases
  )
  const [casesSolvedBySelfService, setCasesSolvedBySelfService] = useState(
    defaultState.casesSolvedBySelfService
  )
  const [costSavedBySelfService, setCostSavedBySelfService] = useState(
    defaultState.costSavedBySelfService
  )
  const [costSavedByAgent, setCostSavedByAgent] = useState(
    defaultState.costSavedByAgent
  )

  const [queryParams, setQueryParams] = useQueryParam(
    "data",
    // TODO:  This would be nicer to use ObjectParams but the link gets very long ang ugly, we can
    // change that when we have a URL shortening available
    DelimitedArrayParam
  )

  React.useEffect(() => {
    if (queryParams) {
      setSupportRequests(queryParams[0])
      setServiceAgents(queryParams[1])
      setCurrency(queryParams[2])
      setAverageSalary(queryParams[3])
      setProductCategory(queryParams[4])
      setWorkingDaysPerMonth(queryParams[5])
      setWorkingHoursPerDay(queryParams[6])
      setCompanyName(queryParams[7])
      setEmail(queryParams[8])
      setCostPerCase(queryParams[9])
      setCostPerCaseSaved(queryParams[10])
      setSavedHours(queryParams[11])
      setCostSavedYearly(queryParams[12])
      setTimePerCase(queryParams[13])
      setSelfServiceCases(queryParams[14])
      setCasesSolvedBySelfService(queryParams[15])
      setCostSavedBySelfService(queryParams[16])
      setCostSavedByAgent(queryParams[17])
    }
  }, [queryParams])

  const storeData = payload => {
    if (data) setLocalStorage(LOCAL_STORAGE_KEY, { ...data, ...payload })
    else setLocalStorage(LOCAL_STORAGE_KEY, payload)
  }

  const stepDone = (step, payload) => {
    switch (step) {
      case "HERO":
        break
      case "ROI_INPUTS":
        storeData({ roiInputs: payload })
        trackEvent({
          category: "view_item",
          label: "roi parameters entered",
          siteSection: "roi_parameters_submit",
        })
        break
      case "MORE_INFO":
        storeData({ roiDetails: payload })
        trackEvent({
          category: "generate_lead",
          label: "roi user details submitted",
          siteSection: "roi_user_details_form",
        })
        identify(payload.email, payload.companyName)
        break
      default:
        break
    }
  }

  const getQueryParamsArray = () => [
    supportRequests,
    serviceAgents,
    currency,
    averageSalary,
    productCategory,
    workingDaysPerMonth,
    workingHoursPerDay,
    companyName,
    email,
    costPerCase,
    costPerCaseSaved,
    savedHours,
    costSavedYearly,
    timePerCase,
    selfServiceCases,
    casesSolvedBySelfService,
    costSavedBySelfService,
    costSavedByAgent,
  ]

  const createSharableLink = () => {
    const queryParamsArray = getQueryParamsArray()
    setQueryParams(queryParamsArray)
  }

  const reset = React.useCallback(() => {
    setSupportRequests(defaultState.supportRequests)
    setServiceAgents(defaultState.serviceAgents)
    setCurrency(defaultState.currency)
    setAverageSalary(defaultState.averageSalary)
    setProductCategory(defaultState.productCategory)
    setWorkingDaysPerMonth(defaultState.workingDaysPerMonth)
    setWorkingHoursPerDay(defaultState.workingHoursPerDay)
    setCompanyName(defaultState.companyName)
    setEmail(defaultState.email)
    setCostPerCase(defaultState.costPerCase)
    setCostPerCaseSaved(defaultState.costPerCaseSaved)
    setSavedHours(defaultState.savedHours)
    setCostSavedYearly(defaultState.costSavedYearly)
    setTimePerCase(defaultState.timePerCase)
    setSelfServiceCases(defaultState.selfServiceCases)
    setCasesSolvedBySelfService(defaultState.casesSolvedBySelfService)
    setCostSavedBySelfService(defaultState.costSavedBySelfService)
    setCostSavedByAgent(defaultState.costSavedByAgent)
    resetLocalStorage()
  }, [])

  const calcRoi = React.useCallback(() => {
    const casesPerDayAgent =
      supportRequests / serviceAgents / workingDaysPerMonth

    const casesRoutedThruMav = supportRequests * (selfServiceCases / 100)
    const correctlyResolved = casesRoutedThruMav * CORRECTLY_RESOLVED
    const thruSelfService = correctlyResolved * (casesSolvedBySelfService / 100)
    const thruSupportAgent =
      correctlyResolved * (1 - casesSolvedBySelfService / 100)

    const cpc =
      (averageSalary * MULTIPLIER) / workingDaysPerMonth / casesPerDayAgent

    const selfServiceSaving =
      thruSelfService * cpc * (costSavedBySelfService / 100)
    const agentSaving = thruSupportAgent * (costSavedByAgent / 100) * cpc

    const cpcs = (selfServiceSaving + agentSaving) / supportRequests
    const ycs = (selfServiceSaving + agentSaving) * 12

    const timePerCaseCalc = workingHoursPerDay / casesPerDayAgent
    const timeSaving =
      12 *
      timePerCaseCalc *
      supportRequests *
      ((casesSolvedBySelfService / 100) * (costSavedBySelfService / 100) +
        (1 - casesSolvedBySelfService / 100) * (costSavedByAgent / 100))

    setCostPerCase(cpc)
    setCostPerCaseSaved(cpcs)
    setSavedHours(timeSaving)
    setCostSavedYearly(ycs)
    setTimePerCase(timePerCaseCalc)
  }, [
    averageSalary,
    casesSolvedBySelfService,
    costSavedByAgent,
    costSavedBySelfService,
    selfServiceCases,
    serviceAgents,
    supportRequests,
    workingDaysPerMonth,
    workingHoursPerDay,
  ])

  return (
    <RoiContext.Provider
      value={{
        supportRequests,
        serviceAgents,
        currency,
        averageSalary,
        productCategory,
        workingDaysPerMonth,
        workingHoursPerDay,
        companyName,
        email,

        costPerCase,
        costPerCaseSaved,
        savedHours,
        costSavedYearly,
        timePerCase,

        selfServiceCases,
        casesSolvedBySelfService,
        costSavedBySelfService,
        costSavedByAgent,
        stepDone,
        setSupportRequests,
        setServiceAgents,
        setCurrency,
        setAverageSalary,
        setWorkingDaysPerMonth,
        setWorkingHoursPerDay,
        setProductCategory,
        setCompanyName,
        setEmail,

        setSelfServiceCases,
        setCasesSolvedBySelfService,
        setCostSavedBySelfService,
        setCostSavedByAgent,

        reset,
        calcRoi,
        createSharableLink,
        getQueryParamsArray,
      }}
    >
      {children}
    </RoiContext.Provider>
  )
}

export const useRoiContext = () => React.useContext(RoiContext)
