export default () => {
  /* eslint-disable no-unused-expressions, no-undef */
  var _linkedin_partner_id = "1252708"
  window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || []
  window._linkedin_data_partner_ids.push(_linkedin_partner_id)
  ;(function() {
    var s = document.getElementsByTagName("script")[0]
    var b = document.createElement("script")
    b.type = "text/javascript"
    b.async = true
    b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"
    s.parentNode.insertBefore(b, s)
  })()
  /* eslint-enable no-unused-expressions, no-undef */

  const elem = document.createElement("img")
  elem.setAttribute("height", "1")
  elem.setAttribute("width", "1")
  elem.setAttribute("style", "display: none")
  elem.setAttribute("alt", "")
  elem.setAttribute(
    "src",
    "https://px.ads.linkedin.com/collect/?pid=1252708&fmt=gif"
  )
  document.body.appendChild(elem)
}
