// @ts-check
const colors = {
  black: "#000",
  dark: "#311A57",
  lightpurple: "#5C477E",
  darkpurple: "#453461",

  darkgrey: "#333333",
  dimgrey: "#8A8A8A",
  grey: "#B2B2B2",
  lightgrey: "#F0F0F0",

  light: "#F4DDD7",
  white: "#FFF",

  ces: "#DEE13F",

  primary: "#F96443",
  error: "#FB3232", // red

  green: "#219653",
  transparentLight: "transparent",
  transparentDark: "transparent",
}

/** @typedef { keyof typeof colors } TColor */

export default colors

/**
 * Mapping of contrast text colors, given a background color
 * @type {Record<TColor, string>}
 */
export const foregroundColors = {
  white: colors.darkgrey,
  light: colors.dark,
  dark: colors.white,
  black: colors.white,
  lightpurple: colors.white,
  darkgrey: colors.white,
  dimgrey: colors.light,
  lightgrey: colors.primary,
  grey: colors.dark,
  primary: colors.white,
  error: colors.light,
  ces: colors.dark,
  green: colors.white,
  darkpurple: colors.white,
  transparentLight: colors.white,
  transparentDark: colors.darkgrey,
}

/**
 * Mapping of contrast text colors, given a background color
 * @type {Record<TColor, string>}
 */
export const hoverColors = {
  white: "#999",
  light: colors.light,
  dark: "#5a4879",
  black: colors.black,
  lightpurple: "#756990",
  darkgrey: colors.darkgrey,
  dimgrey: colors.dimgrey,
  lightgrey: colors.lightgrey,
  grey: colors.grey,
  primary: "#F98166",
  error: colors.error,
  ces: colors.ces,
  green: colors.green,
  darkpurple: colors.darkpurple,
  transparentLight: "transparent",
  transparentDark: "transparent",
}
