// @ts-check
import React from "react"
import DemoModal from "../components/demoModal"
import { trackEvent } from "../utils/trackEvent"

/**
 * @typedef {object} DemoContextType
 * @property {[number, number]} startPos
 * @property {Boolean} isShown
 * @property {Boolean} isSuccess
 * @property {string} [trackingFrom]
 * @property {() => void} close
 * @property {(e: React.MouseEvent, { category, label, siteSection, from }?: {category: string, label: string, siteSection: string, from?: string}) => void} open
 * @property {() => void} onSuccess
 */

const DemoContext = React.createContext({})

export const DemoProvider = ({ children }) => {
  const [startPos, setStartPos] = React.useState(null)
  const [trackingFrom, setTrackingFrom] = React.useState(null)
  const [isSuccess, setIsSuccess] = React.useState(false)

  const onSuccess = React.useCallback(() => {
    setIsSuccess(true)
    trackEvent({
      category: "generate_lead",
      label: "demo request form submitted",
      siteSection: "demo_form_modal",
      demoRequestedFrom: trackingFrom,
    })
  }, [trackingFrom])
  const open = React.useCallback(
    (
      e,
      { category, from, label, siteSection } = {
        category: "open_get_a_demo",
        from: null,
      }
    ) => {
      setStartPos([e.clientX, e.clientY])
      setTrackingFrom(from)
      trackEvent({
        category,
        label,
        siteSection,
        demoRequestedFrom: from,
      })
    },
    []
  )
  const close = React.useCallback(() => setStartPos(null), [])

  return (
    <DemoContext.Provider
      value={{
        startPos,
        isShown: Boolean(startPos),
        isSuccess,
        trackingFrom,
        close,
        open,
        onSuccess,
      }}
    >
      {children}
      <DemoModal />
    </DemoContext.Provider>
  )
}

export default DemoContext
