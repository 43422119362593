// @ts-check
import styled from "@emotion/styled"
import { typographyStyles, typographyComponents } from "./typography"
import { borderRadius } from "../styles/constants"
import colors, { foregroundColors } from "../styles/colors"

/**
 * @typedef {object} InputProps
 * @prop {import("../styles/colors").TColor} [variant="light"]
 * @prop {import("../styles/colors").TColor} [focusBorderVariant]
 **/

const Input = styled.input`
  ${typographyStyles.bodySmall};
  padding: 16px;
  margin-bottom: 20px;

  border-radius: ${borderRadius};
  background: ${(/** @type {InputProps} */ p) => colors[p.variant || "light"]};
  color: ${p => foregroundColors[p.variant || "light"]};
  border: 2px solid transparent;

  outline: 0;

  &:focus {
    border-color: ${p =>
      p.focusBorderVariant
        ? colors[p.focusBorderVariant]
        : foregroundColors[p.variant || "light"]};
  }

  &::placeholder {
    color: ${p => foregroundColors[p.variant || "light"]};
    opacity: 0.3;
  }

  &::-moz-placeholder {
    color: ${p => foregroundColors[p.variant || "light"]};
    opacity: 0.3;
  }
`

export const Label = styled(typographyComponents.label)`
  display: block;
  margin-bottom: 4px;
`.withComponent("label")

export default Input
