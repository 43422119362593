// @ts-check
import React from "react"
import { Link as GatsbyLink, navigate } from "gatsby"

import { I18nContext } from "../contexts/i18n"

export const useNavigate = () => {
  const { language: currentLanguage } = React.useContext(I18nContext)
  const nav = (to, forceLanguage) => {
    const language = forceLanguage || currentLanguage
    navigate(language ? `/${language}${to}` : `${to}`)
  }
  return nav
}

/**
 * @typedef {object} TLinkProps
 * @prop {string} [to]
 * @prop {boolean} [disabled]
 * @prop {string} [forceLanguage]
 * @prop {React.ReactNode} children
 */

const Link = (
  /** @type {TLinkProps & Omit<import("gatsby").GatsbyLinkProps, "ref" | "to">} */ {
    to,
    disabled,
    children,
    forceLanguage,
    ...props
  }
) => {
  const { language: currentLanguage } = React.useContext(I18nContext)
  const language = forceLanguage || currentLanguage

  if (!to || disabled) return <span {...props}>{children}</span>

  if (/^(https?:\/\/|mailto:)/.test(to)) {
    // @ts-expect-error
    const { center, ...other } = props
    return (
      <a href={to} {...other}>
        {children}
      </a>
    )
  }
  return (
    <GatsbyLink to={language ? `/${language}${to}` : `${to}`} {...props}>
      {children}
    </GatsbyLink>
  )
}

export default Link
