// @ts-check
import styled from "@emotion/styled"
import React from "react"

const fontFamilies = {
  brand: "SpaceGrotesk",
  body: "TTInterphases",
}

const fontFamilyFallback = ["Helvetica", "sans-serif"]

/** @param {keyof typeof fontFamilies} type */
export const makeFontFamily = type =>
  [fontFamilies[type], ...fontFamilyFallback].join(",")
/**
 * All style definitions for different typography variants
 */
export const typographyStyles = {
  h1: `
    font-family: ${makeFontFamily("brand")};
    font-size: 80px;
    line-height: 84px;
    letter-spacing: -0.02em;
    font-weight: 700;
    
    @media screen and (max-width: 768px) {
      font-size: 50px;
      line-height: 55px;
    }
    
    @media screen and (max-width: 450px) {
      font-size: 34px;
      line-height: 38px;
      letter-spacing: -0.01em;
    }
  `,

  h2: `
    font-size: 60px;
    line-height: 64px;
    letter-spacing: -0.02em;
    font-weight: 600;

    @media screen and (max-width: 768px) {
      font-size: 30px;
      line-height: 38px;
      letter-spacing: -0.01em;
    }
  `,

  h3: `
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.01em;
    font-weight: 700;

    @media screen and (max-width: 768px) {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: -0.01em;
    }
  `,

  h4: `
    font-size: 40px;
    line-height: 44px;
    letter-spacing: -0.01em;
    font-weight: 500;
    
    @media screen and (max-width: 768px) {
      font-size: 26px;
      line-height: 30px;
      letter-spacing: -0.02em;
    }
  `,

  h5: `
    font-size: 30px;
    line-height: 34px;
    letter-spacing: -0.01em;
    font-weight: 600;

    @media screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
    }
  `,

  h6: `
    font-size: 24px;
    line-height: 30px;
    letter-spacing: -0.01em;
    font-weight: 600;

    @media screen and (max-width: 768px) {
      font-size: 24px;
      line-height: 28px;
    }
  `,

  hero: `
    font-size: 108px;
    line-height: 108px;
    letter-spacing: -0.02em;
    font-weight: 700;
    
    @media (max-width: 768px) {
      font-size: 70px;
      line-height: 75px;
    }
    
    @media (max-width: 500px) {
      font-size: 40px;
      line-height: 44px;
      letter-spacing: -0.01em;
    }
  `,

  subtitleLarge: `
    font-size: 20px;
    line-height: 26px;
    font-weight: 700;
  `,
  subtitleSmall: `
    font-size: 16px;
    line-height: 22px;
    font-weight: 600;
  `,
  bodyLarge: `
    font-size: 20px;
    line-height: 26px;
    font-weight: 500;
  `,
  bodySmall: `
    font-size: 16px;
    line-height: 22px;
    font-weight: 400;
  `,
  smallButton: `
    font-size: 16px;
    line-height: 18px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;

    @media screen and (max-width: 768px) {
      font-size: 12px;
      line-height: 14px;
    }
  `,
  overline: `
    font-size: 14px;
    line-height: 16px;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.03em;
  `,

  small: `
    font-size: 12px;
    line-height: 14px;
    letter-spacing: -0.01em;
    font-weight: normal;
  `,

  // Old typography styles
  label: `
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: bold;

    @media screen and (max-width: 768px) {
      font-size: 13px;
      line-height: 17px;
    }
  `,

  caption: `
    font-size: 15px;
    line-height: 20px;
    letter-spacing: -0.02em;
    font-weight: normal;
  `,

  button: `
    font-size: 16px;
    line-height: 18px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    font-weight: 600;

    @media screen and (max-width: 768px) {
      font-size: 14px;
      line-height: 16px;
    }
  `,
}

/**
 * @typedef {keyof typeof typographyStyles} TypographyVariants
 */

/**
 * All component definitions for different typography variants.
 * Can be useful to avoid using `<Typography variant="..." />` in reused components
 */
export const typographyComponents = /** @type {{ [variant in TypographyVariants]: import("@emotion/styled").StyledComponent }} */ (Object.keys(
  typographyStyles
).reduce(
  (a, b) => ({
    ...a,
    [b]: styled.span`
      ${typographyStyles[b]}
    `,
  }),
  {}
))

/**
 * @param {{variant: TypographyVariants, as?: string | React.FC} & React.AllHTMLAttributes<HTMLElement>} props
 */
const Typography = ({ variant, as, ...props }) => {
  const Component = typographyComponents[variant]
  return <Component as={as} {...props} />
}

export default Typography

export const TypographyMarkdown = styled.div`
  a {
    color: inherit;
  }

  h1 {
    ${typographyStyles.h1};
  }

  h2 {
    ${typographyStyles.h2}
  }

  h3 {
    ${typographyStyles.h3}
  }

  h4 {
    ${typographyStyles.h4}
  }

  h5 {
    ${typographyStyles.h5}
  }

  h6 {
    ${typographyStyles.h6}
  }

  p,
  div,
  li {
    ${typographyStyles.bodyLarge}
  }
`
