export default () => {
  /* eslint-disable no-unused-expressions, no-undef */
  !(function(f, b, e, v, n, t, s) {
    if (f.fbq) return
    n = f.fbq = function() {
      n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
    }
    if (!f._fbq) f._fbq = n
    n.push = n
    n.loaded = !0
    n.version = "2.0"
    n.queue = []
    t = b.createElement(e)
    t.async = !0
    t.src = v
    s = b.getElementsByTagName(e)[0]
    s.parentNode.insertBefore(t, s)
  })(
    window,
    document,
    "script",
    "https://connect.facebook.net/en_US/fbevents.js"
  )
  fbq("init", "805485749871886")
  fbq("track", "PageView")

  /* eslint-enable no-unused-expressions, no-undef */

  const elem = document.createElement("img")
  elem.setAttribute("height", "1")
  elem.setAttribute("width", "1")
  elem.setAttribute("style", "display: none")
  elem.setAttribute("alt", "")
  elem.setAttribute(
    "src",
    "https://www.facebook.com/tr?id=805485749871886&ev=PageView&noscript=1"
  )
  document.body.appendChild(elem)
}
