// prettier-ignore
export default () => {
  // eslint-disable-next-line no-unused-expressions
  !function(){var analytics=window.analytics=window.analytics||[];if(!analytics.initialize)if(analytics.invoked)window.console&&console.error&&console.error("Segment snippet included twice.");else{analytics.invoked=!0;analytics.methods=["trackSubmit","trackClick","trackLink","trackForm","pageview","identify","reset","group","track","ready","alias","debug","page","once","off","on"];analytics.factory=function(t){return function(){var e=Array.prototype.slice.call(arguments);e.unshift(t);analytics.push(e);return analytics}};for(var t=0;t<analytics.methods.length;t++){var e=analytics.methods[t];analytics[e]=analytics.factory(e)}analytics.load=function(t,e){var n=document.createElement("script");n.type="text/javascript";n.async=!0;n.src="https://cdn.segment.com/analytics.js/v1/"+t+"/analytics.min.js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(n,a);analytics._loadOptions=e};analytics.SNIPPET_VERSION="4.1.0";
    analytics.load("mKyAHTklni4dtaqobf1EYNbfByvoO0Wc");
  }}();
}

// @ts-check
export const pageView = () => window.analytics && window.analytics.page()

/** @param {Error} err */
export const trackError = err => {
  console.error(err)
  if (window.analytics && window.analytics.track) {
    window.analytics.track("JavaScript Error", {
      message: err.message,
      stack: err.stack,
      name: err.name,
      json: JSON.stringify(err, null, 2),
      // for ajax errors
      response: err.response
        ? JSON.stringify(err.response, null, 2)
        : undefined,
      request: err.request ? JSON.stringify(err.request, null, 2) : undefined,
    })
  }
}
