import React from "react"
import { installServicesAfterConsent, pageView } from "../utils/tracking"

export const COOKIE_CONTENT_LS_KEY = "cookie-consent"

const CookieConsentContext = React.createContext({ initialConsent: [] })

const getItemLocalStorage = () =>
  typeof window !== `undefined`
    ? JSON.parse(localStorage.getItem(COOKIE_CONTENT_LS_KEY))
    : null
const setLocalStorage = data =>
  localStorage.setItem(COOKIE_CONTENT_LS_KEY, JSON.stringify(data))

const isArraysEqual = (a, b) =>
  Array.isArray(a) &&
  Array.isArray(b) &&
  a.length === b.length &&
  a.every((val, i) => val === b[i])

export const CookieConsentProvider = ({ children }) => {
  const cc = getItemLocalStorage()
  const [isOpen, setIsOpen] = React.useState(cc === null)

  const open = () => setIsOpen(true)

  const resetFirstPartyCookies = () => {
    const allCookies = document.cookie.split(";")

    allCookies.forEach(cookie => {
      document.cookie = `${cookie}=;path=/;expires=Thu, 01 Jan 1970 00:00:00 GMT`
    })
  }

  const close = consent => {
    resetFirstPartyCookies()
    setLocalStorage(consent)
    setIsOpen(false)
    installServicesAfterConsent()

    if (cc !== null && cc !== [] && !isArraysEqual(cc, consent)) {
      // eslint-disable-next-line
      location.reload()
    } else {
      pageView()
    }
  }

  React.useEffect(() => {
    if (cc === null) {
      setIsOpen(true)
    } else {
      installServicesAfterConsent()
    }
  }, [cc])

  return (
    <CookieConsentContext.Provider
      value={{ initialConsent: cc || [], isOpen, open, close }}
    >
      {children}
    </CookieConsentContext.Provider>
  )
}

export const useCookieConsentContext = () =>
  React.useContext(CookieConsentContext)
