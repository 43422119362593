// @ts-check
import React from "react"
import styled from "@emotion/styled"
import { typographyComponents } from "./typography"
import colors, { foregroundColors, hoverColors } from "../styles/colors"
import { borderRadius } from "../styles/constants"
import BigArrow from "./icons/bigArrow"
import Link from "./link"

/**
 * @typedef {"m" | "s" | "xs"} TButtonSize
 */

/** @type { { [size in TButtonSize]: number } } */
const minWidths = {
  m: 160,
  s: 100,
  xs: 70,
}

/** @type { { [size in TButtonSize]: string } } */
const paddings = {
  m: "16px 28px",
  s: "10px 18px",
  xs: "6px 10px",
}

/** @typedef {object} IButtonProps
 *  @prop {TButtonSize} [size]
 *  @prop {import("../styles/colors").TColor} [variant]
 *  @prop {boolean} [inverted]
 */

const BaseButton = typographyComponents.button.withComponent("button")

export const BigPillButton = styled(
  typographyComponents.bodyLarge.withComponent("button")
)`
  text-transform: initial;
  border: 2px solid;

  display: flex;
  align-items: center;
  justify-content: center;

  min-width: 190px;
  padding: 35px 30px;

  @media screen and (max-width: 768px) {
    padding: 35px 25px;
  }

  background: transparent;
  color: ${p => colors[p.variant || "primary"]};
  border-color: ${p => colors[p.variant || "primary"]};

  border-radius: 100px;
  text-decoration: none;
  cursor: pointer;

  outline-color: ${p => foregroundColors[p.variant || "primary"]};

  transition: background-color 0.2s, color 0.2s;

  &:active {
    ${p =>
      `background-color: ${colors[p.variant || "primary"]}; 
      color: ${foregroundColors[p.variant || "primary"]};
      transition: none;`}
  }

  @media screen and (min-width: 769px) {
    &:hover {
      ${p =>
        `background-color: ${colors[p.variant || "primary"]}; 
        color: ${foregroundColors[p.variant || "primary"]}`}
    }
  }
`
const Button = styled(BaseButton)`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: ${p => (p.center ? "center" : "flex-start")};

  min-width: ${p => minWidths[p.size || "m"]}px;
  padding: ${p => paddings[p.size || "m"]};
  height: 44px;
  border: 2px solid;

  background: ${p =>
    p.ghost ? "transparent" : colors[p.variant || "primary"]};
  color: ${p => foregroundColors[p.variant || "primary"]};
  border-color: ${p =>
    p.ghost
      ? foregroundColors[p.variant || "primary"]
      : colors[p.variant || "primary"]};
  border-radius: ${borderRadius};
  text-decoration: none;
  cursor: pointer;

  outline-color: ${p => foregroundColors[p.variant || "primary"]};

  transition: background-color 0.2s, color 0.2s;
`

export default Button

export const HoverButton = styled(Button)`
  &[disabled] {
    pointer-events: none;
  }

  &:hover {
    background-color: ${p =>
      p.ghost
        ? foregroundColors[p.variant || "primary"]
        : hoverColors[p.variant || "primary"]};
    border-color: ${p =>
      p.ghost
        ? foregroundColors[p.variant || "primary"]
        : hoverColors[p.variant || "primary"]};
    color: ${p =>
      p.ghost
        ? colors[p.variant || "primary"]
        : foregroundColors[p.variant || "primary"]};
  }
`

export const SendingButton = styled(Button)`
  display: block;
  position: relative;
  transition: color 0.2s;
  ${(/** @type {{ isSending: boolean }}*/ p) =>
    p.isSending ? "color: transparent;" : ""}

  ${p =>
    !p.isSending &&
    `
      &[disabled] {
        background-color: ${colors.grey};
        color: ${colors.dimgrey};
        opacity: 0.7;
      }
  `}

  &:after {
    content: "Sending...";
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: ${p => (p.isSending ? 1 : 0)};
    color: ${colors.light};
    transform: translateX(${p => (p.isSending ? 0 : -10)}px);
    transition: opacity 0.2s, transform 0.2s cubic-bezier(0.86, 0, 0.07, 1);
  }
`

export const ButtonWithIcon = styled(HoverButton)`
  border-radius: 1000px;
  border: 2px solid ${p => foregroundColors[p.variant || "darkgrey"]};
  color: ${p => foregroundColors[p.variant || "darkgrey"]};
  text-transform: none;
  padding: ${p =>
    p.iconPos === "left" ? "1rem 1.5rem 1rem 1rem" : "1rem 1rem 1rem 1.5rem"};
  max-width: 330px;
  align-self: baseline;
`

export const ButtonWithArrow = ({
  to = "",
  arrowWidth = "18px",
  children,
  ...props
}) => {
  return (
    <ButtonWithIcon iconPos="left" {...(to ? { as: Link, to } : {})} {...props}>
      <BigArrow
        style={{
          minWidth: arrowWidth,
          width: arrowWidth,
          marginRight: "1rem",
        }}
      />
      {children}
    </ButtonWithIcon>
  )
}
