// @ts-check
export const rawBorderRadius = 4
export const borderRadius = `${rawBorderRadius}px`

export const DesktopBreakPoint = 66

export const DesktopJSMediaQuery = `(min-width: ${DesktopBreakPoint}rem)`
export const DesktopMediaQuery = `@media ${DesktopJSMediaQuery}`
export const MobileJSMediaQuery = `(max-width: ${DesktopBreakPoint - 0.1}rem)`
export const MobileMediaQuery = `@media ${MobileJSMediaQuery}`

export const CARD_MAX_WIDTH = 1200
export const CARD_MAX_WIDTH_HEADER = 1360

export const BORDER_RADIUS_SMALL = 4
export const BORDER_RADIUS_MEDIUM = 16
export const BORDER_RADIUS_LARGE = 24

export const PADDING_VERTICAL = 70
export const PADDING_HORIZONTAL = 80
export const MOBILE_PADDING_VERTICAL = PADDING_VERTICAL / 2
export const MOBILE_PADDING_HORIZONTAL = 25

export const SPACING_SMALL = 1
export const SPACING_MEDIUM = 3
export const SPACING_LARGE = 5
