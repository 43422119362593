// @ts-check
import installSegment, { pageView as segmentPageView } from "./segment"
import installFacebookPixel from "./facebookPixel"
import installLinkedinInsight from "./linkedinInsight"
import { COOKIE_CONTENT_LS_KEY } from "../contexts/cookieConsent"

export const installServicesAfterConsent = () => {
  const lsCookieConsentString = window.localStorage.getItem(
    COOKIE_CONTENT_LS_KEY
  )
  if (lsCookieConsentString) {
    const cookieConsent = JSON.parse(lsCookieConsentString)

    // Only install the allowed cookies from the consent of the user
    cookieConsent.forEach(cc => {
      switch (cc) {
        case "Preferences": {
          break
        }

        case "Statistics": {
          installSegment()
          break
        }

        case "Marketing": {
          installFacebookPixel()
          installLinkedinInsight()
          break
        }

        default:
          break
      }
    })
  }
}

// Install every 3rd party service that doesn't require explicit consent
export const install = () => {}

export const pageView = () => {
  segmentPageView()
}
