// @ts-check
import React from "react"

const Cross = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg fill="currentColor" viewBox="0 0 16 16" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.1499 1.14999C9.1499.514862 8.63501 0 7.99988 0c-.63513 0-1.14997.514862-1.14997 1.14999V6.8501H1.15002C.514893 6.8501 0 7.36496 0 8.00009c0 .63513.514893 1.15 1.15002 1.15h5.69989V14.85c0 .6351.51484 1.15 1.14997 1.15s1.15002-.5149 1.15002-1.15V9.15009H14.85c.6351 0 1.15-.51487 1.15-1.15 0-.63513-.5149-1.14999-1.15-1.14999H9.1499V1.14999z"
    />
  </svg>
)

export default Cross
