// @ts-check
import React from "react"
import styled from "@emotion/styled"

const AspectRatioWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${(/** @type {{ratio: number}} */ p) => p.ratio * 100}%;
`

/**
 * @typedef {object} AspectRatioProps
 * @prop {number} [ratio=1] Number that fits the equation `height = ratio * width`
 * @prop {React.ReactElement} children
 **/

/** @param {AspectRatioProps & Omit<React.HTMLAttributes<HTMLDivElement>, "children">} props */
const AspectRatio = ({ ratio = 1, children, ...p }) => (
  <AspectRatioWrapper ratio={ratio} {...p}>
    {React.cloneElement(children, {
      style: {
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        height: "100%",
        width: "100%",
        ...(children.props.style ?? {}),
      },
    })}
  </AspectRatioWrapper>
)

export default AspectRatio
