//@ts-check
import React from "react"
import styled from "@emotion/styled"
import { keyframes } from "@emotion/react"

import Cross from "./icons/cross"
import colors from "../styles/colors"

const overlayKeyframes = keyframes`
  from {
    transform: translate(-50%, -50%) scale(0.01);
    opacity: .2;
  } to {
    transform: translate(-50%, -50%) scale(3);
    opacity: 1;
  }
`

const Overlay = styled.div`
  position: fixed;
  top: ${/** @param {{ startPos: [number, number]}} p */ p => p.startPos[1]}px;
  left: ${p => p.startPos[0]}px;
  width: 500px;
  height: 500px;
  background: ${colors.primary};
  border-radius: 500px;
  z-index: 120;
  animation: ${overlayKeyframes} 0.4s cubic-bezier(0.23, 1, 0.32, 1) forwards;
  pointer-events: none;
`

const contentKeyframes = keyframes`
  from {
    opacity: 0;
  } to {
    opacity: 1;
  }
`
const ContentOuterWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 121;
  background: ${colors.primary};
  color: ${colors.white};
  overflow-y: scroll;
  animation: ${contentKeyframes} 0.5s 0.1s cubic-bezier(0.23, 1, 0.32, 1) both;
`
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-shrink: 0;
  margin: 0 10px;
  padding-top: 15vh;
  padding-bottom: 50px;

  @media screen and (max-width: 768px) {
    padding-top: 30px;
  }
`

const CloseButton = styled.button`
  position: fixed;
  top: 30px;
  right: 30px;
  align-self: flex-end;
  color: inherit;
  background: none;
  border: none;
  cursor: pointer;

  svg {
    width: 32px;
    height: 32px;
  }

  @media screen and (max-width: 768px) {
    top: 20px;
    right: 8px;
  }
`

/**
 * @param {Object} props
 * @param {[number, number]} props.startPos
 * @param {boolean} [props.isShown=false]
 * @param {() => void} [props.onClose]
 * @param {React.ReactNode | React.ReactNode[]} [props.children]
 */
const Modal = ({ isShown = false, startPos, onClose, children = null }) => {
  // ESC key handler
  React.useEffect(() => {
    if (!isShown || !onClose) return
    const handler = ({ keyCode }) => {
      if (keyCode === 27) onClose()
    }
    document.addEventListener("keydown", handler)
    return () => document.removeEventListener("keydown", handler)
  }, [isShown, onClose])

  // scroll blocker
  React.useEffect(() => {
    if (!isShown) return
    document.body.style.overflow = "hidden"
    return () => {
      document.body.style.overflow = null
    }
  }, [isShown])

  if (!isShown) return null

  return (
    <>
      <Overlay startPos={startPos} />
      <ContentOuterWrapper>
        <CloseButton onClick={onClose} aria-label="close popup">
          <Cross style={{ transform: "rotate(45deg)" }} />
        </CloseButton>
        <ContentWrapper>{children}</ContentWrapper>
      </ContentOuterWrapper>
    </>
  )
}

export default Modal
