const blacklistedDomains = require("./domainBlacklist.json")

// Construct email regex from the domains
const personalEmailRegex = new RegExp(
  `@(${blacklistedDomains.domains.join("|")})\\.`,
  "i"
)

/** @param {string} email */
exports.isPersonalEmail = email => personalEmailRegex.test(email)

/** @param {string} item */
exports.slugify = item =>
  item
    .toLowerCase()
    .trim()
    .replace(/[\s]/g, "-")
    .replace(/[^a-z0-9-]/g, "")
