// @ts-check
import React from "react"
import axios from "axios"
import styled from "@emotion/styled"

import DemoContext from "../contexts/demo"
import colors from "../styles/colors"
import Typography, { typographyComponents } from "./typography"
import { SendingButton } from "./button"
import { FUNCTIONS_ENDPOINT } from "../config"

import Input, { Label } from "./input"
import { trackError } from "../utils/segment"
import { identify } from "../utils/trackEvent"
import Modal from "./modal"
import { isPersonalEmail } from "../../shared/utils"
import AspectRatio from "./aspectRatio"

const Title = styled(typographyComponents.h2.withComponent("h1"))`
  text-align: center;
  margin: 0 0 50px;

  @media (max-width: 768px) {
    margin: 30px 0 20px;
  }
`

const AspectRationContainer = styled.div`
  margin-top: 50px;
  width: 100%;
  max-width: 800px;

  @media (min-width: 768px) {
    margin-top: 150px;
  }
`

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: stretch;
`
const ModalButton = styled(SendingButton)`
  flex-shrink: 0;
  margin-top: 10px;
`
const ErrorText = styled(typographyComponents.small)`
  color: ${colors.dark};
`

const DemoModalForm = ({ onSuccess }) => {
  const [name, setName] = React.useState("")
  const [email, setEmail] = React.useState("")
  const [isSending, setIsSending] = React.useState(false)
  const { trackingFrom } = React.useContext(DemoContext)

  const onSubmit = React.useCallback(
    async e => {
      e.preventDefault()
      setIsSending(true)
      identify(email)
      try {
        const response = await axios.post(
          `${FUNCTIONS_ENDPOINT}/request-demo`,
          {
            email,
            name,
            from: trackingFrom,
          }
        )
        if (response.data.status === "success") {
          onSuccess()
        } else {
          throw new Error(
            `Demo request failed: ${JSON.stringify(response.data, null, 2)}`
          )
        }
      } catch (e) {
        trackError(e)
      } finally {
        setIsSending(false)
      }
    },
    [email, name, trackingFrom, onSuccess]
  )

  const isPersonal = isPersonalEmail(email)

  return (
    <Form onSubmit={onSubmit}>
      <Label as="label" htmlFor="demo-name">
        Name
      </Label>
      <Input
        id="demo-name"
        onChange={e => setName(e.target.value)}
        value={name}
        required
        placeholder="John Smith"
      />

      <Label as="label" htmlFor="demo-email">
        Business email
      </Label>
      <Input
        id="demo-email"
        type="email"
        onChange={e => setEmail(e.target.value)}
        value={email}
        required
        placeholder="john.smith@company.com"
      />
      {isPersonal && (
        <ErrorText>
          <span aria-hidden>⚠️</span> It looks like you have entered your
          personal e-mail address. Right now our product is intended for
          business use only. To request a demo, please use your business email
          address (no Gmail, Hotmail etc).
        </ErrorText>
      )}

      <ModalButton
        variant="dark"
        type="submit"
        disabled={isSending || isPersonal}
        isSending={isSending}
      >
        Get my demo
      </ModalButton>
    </Form>
  )
}

const DemoModal = () => {
  const { isShown, isSuccess, startPos, close, onSuccess } = React.useContext(
    DemoContext
  )

  const youtubeSP = new URLSearchParams()
  youtubeSP.set("modestbranding", "1")
  youtubeSP.set("rel", "0")

  return (
    <Modal startPos={startPos} onClose={close} isShown={isShown}>
      <div>
        {!isSuccess ? (
          <>
            <Title>Schedule a demo today!</Title>
            <DemoModalForm onSuccess={onSuccess} />
          </>
        ) : (
          <>
            <Title>Your demo request has been received</Title>

            <Typography variant="h4">
              We will get back to you shortly to schedule a demo
            </Typography>
          </>
        )}
      </div>
      <AspectRationContainer>
        <AspectRatio ratio={0.66}>
          <iframe
            title="Mavenoid Demo Video"
            src={`https://www.youtube.com/embed/UgBXfIX4Lhk?${youtubeSP}`}
            frameBorder="0"
            allow="autoplay; encrypted-media; picture-in-picture"
            allowFullScreen
          />
        </AspectRatio>
      </AspectRationContainer>
    </Modal>
  )
}

export default DemoModal
