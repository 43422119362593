// @ts-check
import React from "react"

const BigArrow = (/** @type {React.SVGAttributes} */ { ...props }) => (
  <svg
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 144 145"
    {...props}
  >
    <path d="M140.189 72.5964c0-2.4494-1.004-4.6643-2.619-6.2668L78.183 6.94284c-3.4462-3.4462-9.0876-3.44641-12.5271-.00697-3.4459 3.44593-3.4392 9.08083.0005 12.52053l44.2726 44.2729-90.764.0019c-4.8727-.0002-8.8593 3.9864-8.8591 8.8591.0002 4.8727 3.9871 8.8596 8.8598 8.8598l90.7573.0047-44.2693 44.2702c-3.446 3.446-3.4393 9.08.0004 12.52 3.4462 3.446 9.0811 3.44 12.5206.001L137.57 78.8501c1.622-1.596 2.612-3.8108 2.619-6.2537z" />
  </svg>
)

export default BigArrow
