// @ts-check
import React from "react"

/**
 * @typedef {Object} I18n
 * @property {string} nonLocalizedPath
 * @property {Array<string>} locales
 * @property {string} language
 */

export const I18nContext = React.createContext(/** @type {I18n} */ ({}))

/** @param {{ i18n: I18n, children: React.ReactElement }} props */
export const I18nProvider = ({ i18n, children }) => (
  <I18nContext.Provider value={i18n}>{children}</I18nContext.Provider>
)
