/* eslint-enable */
/**
 * This is a type to describe a GA event.
 * @typedef {Object} TTrackEvent
 * @property {"sign_up_redirect" | "view_item" | "contact" | "open_get_a_demo" | "generate_lead"} category - Assign a broad category for the event
 * @property {string} label - Give a unique name to each event
 * @property {string} siteSection - Give a unique name to the website section
 * @property {string} [featureListIndex] - Optional feature index
 * @property {string} [demoRequestedFrom] - Optional source for demo requests
 * @see https://developers.google.com/analytics/devguides/collection/gtagjs/events#default_google_analytics_events
 */
/**
 * This function is used to track a custom event to
 * Segment which forwards it to GTM data layer.
 * @param {TTrackEvent} event - The event that needs to be tracked
 */
export const trackEvent = event => {
  // Wrap inside try catch to avoid blocking any main execution
  try {
    window.analytics && window.analytics.track(event.category, event)
  } catch (e) {}
}

export const identify = (email, companyName) => {
  try {
    window.analytics &&
      window.analytics.identify({
        email,
        companyName,
      })
  } catch (e) {}
}
