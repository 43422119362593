import React from "react"

import { I18nProvider } from "./src/contexts/i18n"
import { DemoProvider } from "./src/contexts/demo"
import { install, pageView } from "./src/utils/tracking"
import { RoiProvider } from "./src/contexts/roiContext"

import "./src/styles/fonts.css"
import { CookieConsentProvider } from "./src/contexts/cookieConsent"

export const wrapPageElement = ({ element, props }) => (
  <I18nProvider i18n={props.pageContext.i18n}>
    <DemoProvider>
      <RoiProvider>
        <CookieConsentProvider>{element}</CookieConsentProvider>
      </RoiProvider>
    </DemoProvider>
  </I18nProvider>
)

export const onClientEntry = () => {
  install()
}

export const onRouteUpdate = ({ location }) => {
  // timeout to wait for the url location to properly change
  setTimeout(() => pageView(), 50)
}
